export const orderIndexSorter = function (a: GalleryItem, b: GalleryItem) {
  return a.orderIndex > b.orderIndex ? 1 : -1;
};

export const getDateCreated = function (galleryItem: GalleryItem, coverIdToAlbum: { [id: string]: GogAlbum }) {
  return new Date(coverIdToAlbum[galleryItem.itemId]?.dateCreated).getTime();
};

export const albumDateCreatedSorter = function (coverIdToAlbum: { [id: string]: GogAlbum }, newestFirst: boolean = true) {
  return (a: GalleryItem, b: GalleryItem) => {
    const aDateCreated = getDateCreated(a, coverIdToAlbum);
    const bDateCreated = getDateCreated(b, coverIdToAlbum);
    return newestFirst ?
      bDateCreated > aDateCreated ? 1 : -1 :
      aDateCreated > bDateCreated ? 1 : -1;
  }
};

export const getSortByFunc = function (sortByKey: number, coverIdToAlbum: { [id: string]: GogAlbum }) {
  switch (sortByKey) {
    case 0:
      return orderIndexSorter;
    case 1:
      return albumDateCreatedSorter(coverIdToAlbum, false);
    case 2:
      return albumDateCreatedSorter(coverIdToAlbum);
    default:
      return orderIndexSorter;
  }
};

export const getSortedAlbums = function (style: any, albumsCovers: GalleryItem[], coverIdToAlbum: {
  [id: string]: GogAlbum;
}) {
  const sortByKey = style && style.styleParams &&
    style.styleParams.numbers && style.styleParams.numbers.sortBy || 0;

  return albumsCovers.sort(getSortByFunc(sortByKey, coverIdToAlbum));
};
